<template>
  <div>
    <v-card
      v-if="!uploading"
      class="pa-10 rounded-xl"
      :style="'background: ' + brandColor || '#ffffff'"
      flat
    >
      <div class="center-logo">
        <app-logo :logo="logo"></app-logo>
      </div>
      <v-toolbar
        class="font-weight-bold text-h5 mt-n5 d-flex justify-center capitalize"
        flat
        color="transparent"
      >
        Final Step, upload your recording
      </v-toolbar>
      <v-form lazy-validation ref="form" class="text-left">
        <div v-if="!requestedLink">
          <label>
            Folder
            <small>(Optional)</small>
          </label>
          <v-select
            :items="folders_item"
            v-model="form.record_folder"
            class="v-folder-items"
            item-text="name"
            item-value="id"
            background-color="secondary"
            placeholder="Choose a folder"
            dense
            solo
            flat
            rounded
          >
            <template v-slot:item="{ item }">
              <div class="text-center">{{ item.name }}</div>
            </template>
          </v-select>
        </div>
        <div v-if="requestedLink">
          <label>Your Name <span class="error--text">*</span></label>
          <v-text-field
            solo
            dense
            flat
            rounded
            background-color="secondary"
            placeholder="Enter your name"
            v-model="form.name"
            :rules="validate.required('Name')"
            type="text"
          ></v-text-field>
          <label>Email Address <span class="error--text">*</span></label>
          <v-text-field
            solo
            dense
            flat
            rounded
            background-color="secondary"
            placeholder="Enter your Email"
            v-model="form.email"
            :rules="validate.required('Email Address')"
            type="email"
          ></v-text-field>
        </div>
        <label>
          Recording Title
          <small>(This can be changed later)</small>
        </label>
        <v-text-field
          solo
          dense
          flat
          rounded
          background-color="secondary"
          placeholder="Enter the recording title"
          v-model="form.record_title"
          type="email"
        ></v-text-field>
        <label>Message <small>(This can be changed later)</small></label>
        <v-textarea
          solo
          dense
          flat
          rounded
          background-color="secondary"
          type="text"
          v-model="form.message"
          placeholder="Type your message"
        ></v-textarea>
      </v-form>
      <v-card-actions class="d-flex justify-center">
        <v-btn class="capitalize grey--text" small text @click="goBack">
          <v-icon>mdi-keyboard-backspace</v-icon>
          Go back
        </v-btn>
        <v-btn
          class="capitalize px-8 white--text"
          rounded
          large
          :color="accentColor || 'primary'"
          :loading="loading"
          @click="$refs.form.validate() ? initiateUpload() : null"
        >
          Upload
        </v-btn>
      </v-card-actions>
    </v-card>
    <div v-show="uploading">
      <upload-progress
        v-for="(file, index) in files"
        v-bind:key="file.file.uniqueIdentifier + index"
        :file="file.file"
        :status="file.status"
        :progress="file.progress"
        :settings="settings"
        @cancel="cancelFile"
      ></upload-progress>
    </div>
  </div>
</template>

<script>
import AppLogo from "./Logo";
import FormValidation from "../utils/FormValidation";
import ResponseHelper from "../utils/ResponseHelper";
import UploadProgress from "./UploadProgress";
import { mapGetters, mapActions } from "vuex";
import Resumable from "resumablejs";
export default {
  components: {
    AppLogo,
    UploadProgress,
  },
  name: "FinalStep",
  props: {
    settings: {
      default: null,
      type: Object,
    },
    resolution: {
      type: Number,
      default: 1000000,
    },
  },
  data() {
    return {
      form: {
        record_folder: null,
      },
      validate: new FormValidation(),
      toast: new ResponseHelper(),
      uploading: false,
      loadCategories: false,
      chunks: [],
      files: [],
      r: false,
      total_chunks: 0,
      total_uploaded: 0,
      upload_message: "",
      video_name: "",
      loading: false,
      folders_item: [],
    };
  },
  created() {
    if (this.$route.path.includes("/record")) {
      this.form.name = this.user.name ?? null;
      this.form.email = this.user.email ?? null;
      this.form.user_id = this.user["account_id"] ?? null;
    }

    this.$root.$on("uploaded-completed", () => {
      this.$parent.recorder.destroy();
      this.$parent.recorder = null;
    });
  },
  mounted() {
    this.myFoldersItem();
  },
  methods: {
    ...mapActions("recordings", ["user_can_upload"]),
    ...mapActions("folders", ["user_folders_list"]),
    goBack() {
      this.$parent.finished = true;
      this.$parent.final_step = false;
      this.loading = false;
    },
    async myFoldersItem() {
      if (this.form.user_id !== null && !this.requestedLink) {
        const userId = this.form.user_id;
        try {
          const response = await this.user_folders_list(userId);
          this.folders_item = response.data;
        } catch (e) {
          this.toast.sendError(e);
        }
      }
    },
    async initiateUpload() {
      this.loading = true;
      this.video_name =
        "video_" + Math.floor(Math.random() * 43210 * 12345) + ".webm";

      this.form.video = new File(
        [this.$parent.recorder.getBlob()],
        this.video_name
      );
      this.form.duration = this.$parent.time;
      if (this.form.duration < 60) {
        this.form.duration = 60;
      }
      const domain = () => {
        if (window.location.href.indexOf(process.env.VUE_APP_URL) === -1) {
          return { domain: window.location.host };
        }
        return null;
      };

      const support = () => {
        if (this.$route.query.support) {
          return { support: this.$route.query.support };
        }
        return null;
      };

      if (this.$route.path.includes("/request")) {
        this.form.requested_link = "Yes";
      }

      const baseURL =
        process.env.NODE_ENV === "production"
          ? process.env.VUE_APP_PRODUCTION_URL
          : process.env.VUE_APP_STAGING_URL;
      try {
        const response = await this.user_can_upload(this.form);
        if (response.message === "User can upload") {
          this.uploading = true;
          // init resumablejs on mount
          this.r = new Resumable({
            target: baseURL + "/recordings/upload-video",
            query: {
              upload_token: "my_token",
              name: this.form.name,
              email: this.form.email,
              record_title: this.form.record_title,
              record_folder: this.form.record_folder,
              category: this.form.category,
              message: this.form.message,
              record_link: this.$route.params.personal_url,
              platform: window.navigator.userAgent,
              size: this.$parent.recorder.getBlob().size,
              duration: this.form.duration,
              label: this.form.label,
              ...support(),
              ...this.requestedLink,
              user_id: this.form.user_id,
              ...domain(),
              quality: this.videoQuality,
            },
            simultaneousUploads: 1,
            maxChunkRetries: 3,
            testChunks: false,
            chunkSize: 1024 * 1024,
            forceChunkSize: true,
          });

          this.r.addFile(this.form.video);

          // Resumable.js isn't supported, fall back on a different method
          if (!this.r.support)
            return alert(
              "Your browser doesn't support chunked uploads. Get a better browser."
            );

          // set up event listeners to feed into vues reactivity
          this.r.on("fileAdded", (file) => {
            file.hasUploaded = false;
            // keep a list of files with some extra data that we can use as props
            this.files.push({
              file,
              status: "uploading",
              progress: 0,
            });
            this.r.upload();
          });
          this.r.on("fileSuccess", (file) => {
            this.findFile(file).status = "success";
          });
          this.r.on("fileError", (file) => {
            this.findFile(file).status = "error";
          });
          this.r.on("fileRetry", (file) => {
            this.findFile(file).status = "retrying";
          });
          this.r.on("fileProgress", (file) => {
            const localFile = this.findFile(file);
            // if we are doing multiple chunks we may get a lower progress number if one chunk response comes back early
            const progress = file.progress();
            if (progress > localFile.progress) localFile.progress = progress;
          });
        }
      } catch (e) {
        this.loading = false;
        this.toast.sendError(e);
      }
    },
    findFile(file) {
      return (
        this.files.find(
          (item) =>
            item.file.uniqueIdentifier === file.uniqueIdentifier &&
            item.status !== "canceled"
        ) ?? {}
      );
    },
    cancelFile(file) {
      this.findFile(file).status = "canceled";
      file.cancel();
    },
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
      default_domain: "domain/default_domain",
    }),
    ...mapGetters("recordings", ["user_details"]),
    requestedLink() {
      if (this.$route.path.includes("/request")) {
        return { requested_link: "Yes" };
      }
      return null;
    },
    brandColor() {
      return (
        this.$parent.settings?.brand_color ||
        this.user_details?.settings?.brand_color
      );
    },
    accentColor() {
      return (
        this.$parent.settings?.accent_color ||
        this.user_details?.settings?.accent_color
      );
    },
    videoQuality() {
      let quality = 1000000;
      const resolution = this.resolution;
      switch (resolution) {
        case 1000000:
          quality = "360p";
          break;
        case 2500000:
          quality = "480p";
          break;
        case 5000000:
          quality = "720p";
          break;
        case 8000000:
          quality = "1080p";
          break;
        case 16000000:
          quality = "2k";
          break;
        case 40000000:
          quality = "4k";
          break;
      }
      return quality;
    },
    logo() {
      return this.settings?.logo_url || null;
    },
  },
};
</script>

<style scoped>
.v-folder-items {
  text-align: left;
}
</style>
